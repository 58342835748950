<template>
  <b-row>
    <b-col
      sm="12"
    >
      <b-card-code
        title="Actualizar Role"
      >
        <b-card-body>
          <FormCreateComponent
            v-if="accessPermissions('roles.show')"
            :id="id"
          />
        </b-card-body>
      </b-card-code>
    </b-col>
    <b-col
      v-if="id !== undefined && accessPermissions('role.permissions.assign')"
      sm="12"
    >
      <b-card-code
        title="Asignar Permisos"
      >
        <b-card-body>
          <FormPermissionComponent
            :id="id"
          />
        </b-card-body>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow,
  BCol,
  BCardBody,
} from 'bootstrap-vue'
import FormCreateComponent from './components/FormCreateComponent.vue'
import FormPermissionComponent from './components/FormPermissionComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'RoleEditPage',
  components: {
    BCardCode,
    BRow,
    BCol,
    BCardBody,
    FormCreateComponent,
    FormPermissionComponent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
  },
}
</script>
