<template>
  <b-row>
    <b-col
      v-for="{ name, permissions, index } in listPermissions"
      :key="index"
      lg="4"
      md="6"
      sm="12"
    >
      <b-card-code
        :title="name"
      >
        <b-form-group
          v-for="item in permissions"
          :key="item.index"
          label-for="permission"
          class="text-wrap"
        >
          <b-form-checkbox
            v-model="permission"
            :value="item.name"
            :disabled="isDisabled(item.name)"
            class="custom-control-primary text-wrap"
          >
            {{ item.name_public }}
          </b-form-checkbox>
        </b-form-group>
      </b-card-code>
    </b-col>
    <b-col
      lg="12"
      md="12"
      sm="12"
    >
      <b-button
        v-if="id !== undefined && accessPermissions('role.permissions.assign') && name !== 'super_administrator'"
        class="float-right text-right"
        variant="primary"
        @click="onHandleAddPermission"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        Guardar
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        class="float-right mr-1"
        :to="{ name: 'settings-roles' }"
      >
        Cancelar
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import router from '@/router'
import PermissionsServices from '../services/PermissionsServices'
import RolesServices from '../services/RolesServices'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormPermissionComponent',
  components: {
    BCardCode,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      permission: ['users.update.profile', 'users.password', 'users.change.photo'],
      listPermissions: [],
    }
  },
  async created() {
    await this.onHandlePermissionsList()
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    isDisabled(value) {
      if (value === 'users.update.profile' || value === 'users.password' || value === 'users.change.photo') {
        return true
      }
      return false
    },
    clearForm() {
      this.permission = ['users.update.profile', 'users.password', 'users.change.photo']
      this.listPermissions = []
    },
    async onHandlePermissionsList() {
      if (this.id !== undefined && this.accessPermissions('role.permissions.assign')) {
        await PermissionsServices.handleList(this.id).then(response => {
          this.listPermissions = response.data.data
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('roles.show')) {
        await RolesServices.handleEdit(this.id).then(response => {
          this.name = response.data.data.name
        }).catch(error => this.errorResp(error))
      }

      if (this.id !== undefined && this.accessPermissions('role.permissions.assign')) {
        await RolesServices.handlePermissionsByRole(this.id).then(response => {
          if (response.data.data && this.listPermissions.length > 0 && response.data.data.permissions.length > 0) {
            const selected = []

            response.data.data.permissions.map(({ name }) => {
              selected.push({ name })
            })

            this.listPermissions.map(({ permissions }) => {
              permissions.map(({ name }) => {
                const find = selected.find(e => (name === e.name))

                if (find !== undefined) {
                  this.permission.push(name)
                }
              })
            })
          }
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleAddPermission() {
      if (this.id !== undefined && this.accessPermissions('role.permissions.assign')) {
        if (this.permission.length === 0) {
          this.swal('Debe seleccionar por lo menos un permiso', 'error')
        } else {
          await RolesServices.handlePermissionsAssignByRole(this.id, { name: this.permission }).then(response => {
            if (response.data.data) {
              this.swal('Los permisos ha sido agregados correctamente', 'success')
              this.clearForm()
              this.onHandlePermissionsList()
              this.onHandleEdit()
              router.push({ name: 'settings-roles' })
            }
          }).catch(error => this.errorResp(error))
        }
      }
    },
  },
}
</script>

<style scoped>

</style>